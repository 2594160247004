<template>
  <b-card
      no-body
    >
    <b-card-header>
        <b-card-title>{{$t($route.name)}}</b-card-title>
         <b-button
              class=""
              variant='success'
              @click='openModal(null, "Create")'
            >
            <feather-icon
              icon="PlusIcon"
              size="15"
            >

            </feather-icon>
            {{$t('add new')}}
          </b-button>
      </b-card-header>
    <b-table
      responsive
      :items="ordersStatus"
      :fields="fields"
      class="mb-0"
    >
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(actions)="row">
          <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
          <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button>
          <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
            :disabled='row.item.default_start == true'
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
            <feather-icon
              icon="KeyIcon"
              size="15"
              class="mx-1"
              v-if='row.item.default_start == true'
            />

          </b-button>
        </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable, BButton, BCard, BCardHeader, BCardTitle } from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCard, BCardHeader, BCardTitle
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('ID')
        },,
         {
          key: 'title',
          label: this.$t('title')
        },
        {
          key: 'status_value',
          label: this.$t('TrueValue'),
          formatter: (value, key, item) => this.formateTrueValue(item),
        },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
    }
  },
  props: ['openModal', 'closeModal', 'confirmDelete'],
  created() {
    this.getOrdersStatus({});
    this.getOrders({})
      .then(res => {
        // console.log(res)
      }).catch(err => console.error(err))
  },

  methods: {
    ...mapActions(['getOrders', 'getOrdersStatus', 'deleteOrderStatus']),

    showItem(row) {
      this.openModal(row, 'Show')
      // console.log(row)
    },
    
    editItem(row) {
      this.openModal(row, 'Edit')
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteOrderStatus, row)
    }
  },

  computed: {
    ...mapGetters(['orders', 'ordersStatus', 'loading']),
  }
}
</script>